const inceptionKey = resolve => require(['@/pages/inception-key/inception-key'], resolve)
const activationManagement = resolve => require(['@/pages/inception-key/activation-code/activation.vue'], resolve)
const activationCodeAdd = resolve => require(['@/pages/inception-key/add_activationcode/index.vue'], resolve)
const applicationManagement = resolve => require(['@/pages/inception-key/application-record/application.vue'], resolve)

export const inception = {
  path: '/inception-key',
  name: 'inception-key',
  component: inceptionKey,

  children: [{
    path: 'activation',
    name: 'activationManagement',
    component: activationManagement,
    meta: {
      keepAlive: true
    },
  }, {
    path: 'application',
    name: 'applicationManagement',
    component: applicationManagement,
    meta: {
      keepAlive: true
    },
  }]
}
export const activationAdd = {
    path: '/inception-key-add',
    name: 'activationCodeAdd',
    component: activationCodeAdd,
}
