const promotions = resolve => require(['@/pages/promo-management/promo'], resolve)
const promotionManagement = resolve => require(['@/pages/promo-management/promo-manage/promo-manage'], resolve)
const pullNewManagement = resolve => require(['@/pages/promo-management/pull_new_manage'], resolve)
const userIncentiveManagement = resolve => require(['@/pages/promo-management/user-incentive-manage/index.vue'], resolve)
export default {
    path:'/promotions',
    name:'promotions',
    component: promotions,
    // redirect: {
    //     name: 'pullNewManagement'
    // },
    children: [{
        path: 'pull-new-management',
        name: 'pullNewManagement',
        component: pullNewManagement,
        meta: {
            keepAlive: true
        },
    }, {
        path: 'user-incentive',
        name: 'userIncentiveManagement',
        component: userIncentiveManagement,
        meta: {
          keepAlive: true
        },
    }, {
        path: 'coupons',
        name: 'couponManagement',
        component: promotionManagement,
        meta: {
            keepAlive: true
        },
    }]
}
