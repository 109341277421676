import userApi from "../api/user";
import {
  mapGetters,
  mapActions,
  mapMutations
} from 'vuex';
const mixin = {
  computed: {
    ...mapGetters({
      currentUsers: "user/userInfo",
      isLoggedIn: 'user/isLogin',
      isLoginApi: 'user/isLoginApi',
    }),
  },

  data() {
    return {
      allResourcesKey: [],
      versionObj: {
        version: '1.1',
        content: 'Winter Is Coming !!!'
      }
    };
  },
  methods: {
    ...mapMutations({
      setIsLoginApi: 'user/setIsLoginApi'
    }),
    ...mapActions({
      login: 'user/logIn',
      logOut: 'user/logOut',
      userUpdate: 'user/userUpdate',
    }),
    hasResource(resource) {
      let person = '';
      let allResource = localStorage.getItem("user-resource");
      if (allResource) {
        person = allResource.split(',');
      } else {
        userApi.adminUserInfos().then((res) => {
          if (res.data.code === 10200) {
            let resource = res.data.data.resources;
            let resourceData = [];
            if (resource) {
              this.getAllResourceKeys(resource);
            }
          }
        });
        person = this.allResourcesKey;
        localStorage.setItem("user-resource", person);
      }
      if (person.indexOf(resource) !== -1) {
        return true;
      }
      if (person.indexOf(resource) === -1) {
        return false;
      }
    },
    getAllResourceKeys(data) {
      let keys = [];
      let key = {};
      for (key in data) {
        let resource = data[key];
        this.allResourcesKey.push(resource["id"]);
        if (resource["children"] != undefined && resource["children"] != []) {
          this.getAllResourceKeys(resource["children"]);
        }
      }
    },
    $$success(message = '成功') {
      this.$message({
        "type": "success",
        "message": message
      });
    },
    $$error(message = '错误') {
      this.$message({
        "type": "error",
        "message": message
      });
    },
    $$warning(message = '警告') {
      this.$message({
        "type": "warning",
        "message": message
      });
    },
    $$upload(message = '上传中') {
      this.message({
        'type': 'info',
        'message': message
      })
    },
    downloadFile(url) {
      let link = document.createElement('a');
      link.style.display = 'none';
      link.href = url;
      document.body.appendChild(link);
      link.click();
    },
    clearForm(formRef) {
      this.$nextTick(() => {
        this.$refs[formRef].resetFields()
      })
    },
    getClientHeight() {
      return document.documentElement.clientHeight;
    },
    getAllLetters() {
      this.letters = [];
      for (let i = 65; i <= 90; i++) {
        this.letters.push(String.fromCharCode(i));
      }
    },
    recordCurrentPage(code, filterParams) {
      window.localStorage[code] = JSON.stringify(filterParams)
    },
    getLocalCurrentPage(code) {
      if(window.localStorage[code] === undefined) return
      let params = JSON.parse(window.localStorage[code]) ? JSON.parse(window.localStorage[code]) : false;
      return params;
    }
  },
};

export default mixin;