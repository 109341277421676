module.exports = {
    envName: 'prod', // 生产
    systemName: "pbr_admin",
    ssn: 'aop',
    appVersion: '2.4.1',
    assetsRoot: 'dist',
    assetsPublicPath: '/',
    serveUrls: 'https://api.prod.pbrmax.cn/zh-CN',
    authBaseURL: 'production',
    webCn: 'https://pbrmax.cn/',  // pbrmax web cn : 国内地址
    webCom: 'https://pbrmax.com/' // pbrmax web com : 国外地址
};
