<template>
  <div class="headerContent">
    <div class="leftHeader">
      <span>
        <i class="iconfont">{{myIcon}}</i>
      </span>
      <!-- <span v-if="config.authBaseURL === 'production'">
        <i class="iconfont">&#xe672;</i>
      </span>
      <span v-if="config.authBaseURL === 'development'">
        <i class="iconfont">&#xe671;</i>
      </span>
      <span v-if="config.authBaseURL === 'test'">
        <i class="iconfont">&#xe671;</i>
      </span>
      <span v-if="config.authBaseURL === 'pre'">
        <i class="iconfont">&#xe670;</i>
      </span> -->
    </div>
    <div></div>
  </div>
</template>

<script>
import api from "../../api/login";
import config from "../../config/index";
import env from 'env';
export default {
  data() {
    return {
      name: "NavMenu",
      props: ["menuList"],
      config: "",
    };
  },
  computed: {
    myIcon() {
      let _iconMap = {
        local: '\ue671',
        autopush: '\ue671',
        staging: '\ue671',
        preprod: '\ue670',
        prod:'\ue672',
      };
      return _iconMap[env.envName] || _iconMap.prod
    }
  },
  methods: {
    init() {
      this.name = localStorage.getItem("username");
    },
    logout() {
      api.userLogout().then((res) => {
        if (res.data.code === 10200) {
          this.$router.push({ path: "/" });
          localStorage.clear();
        } else {
          this.$$error(res.data.message);
        }
      });
    },
  },
  mounted() {
    this.init();
    this.config = config;
  },
};
</script>

<style lang="less" scoped>
.headerContent {
  width: 100%;
}
.leftHeader {
  position: relative;
  i {
    font-style: normal;
    // font-weight: 500;
    font-size: 18px !important;
    line-height: 22px;
    // color: #000;
    position: absolute;
    right: 0.5vw;
    top: 1.8vh;
  }
}
</style>
