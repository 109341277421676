
// 交易管理
const transactionManagement = resolve => require(["@/pages/transaction-management/index"], resolve)
// 积分方案
const plans = resolve => require(["@/pages/transaction-management/plans/index"], resolve)
// 充值订单
const purchaseOrders = resolve => require(["@/pages/transaction-management/orders/index"], resolve)
// 退款订单
const refunds = resolve => require(["@/pages/transaction-management/refunds/index"], resolve)
// 方案审批
const planApproval = resolve => require(["@/pages/transaction-management/planApproval/list"], resolve)


export default {
    path: '/transactions',
    name: 'transactionManagement',
    component: transactionManagement,

    children: [
        {
            path: 'plans',
            name: 'purchasePlans',
            component: plans,
            meta: {
                keepAlive: true
            },
        },
        {
            path: 'planApproval',
            name: 'planApproval',
            component: planApproval,
            meta: {
                keepAlive: true
            },
        },
        {
            path:'orders',
            name:'purchaseOrders',
            component: purchaseOrders,
            meta: {
              keepAlive: true
            },
        },
        {
            path:'refunds',
            name:'refunds',
            component: refunds,
            meta: {
              keepAlive: true
            },
        },
    ]
}
