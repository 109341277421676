const LOG_IN = 'LOG_IN'
const LOG_OUT = 'LOG_OUT'

const state = {
  userInfo: {},
  isLogin: false,
  isLoginApi: true,
}

const getters = {
  isLogin: state => state.isLogin,
  isLoginApi: state => state.isLoginApi,
  userInfo: state => state.userInfo
}

const mutations = {
  setUserInfo(state, data) {
    state.userInfo = {
      ...state.userInfo,
      ...data
    };
  },
  setIsLogin(state, data) {
    state.isLogin = data;
  },
  setIsLoginApi(state, data) {
    state.isLoginApi = data;
  },

  [LOG_IN]: (state, userInfo) => {
    state.userInfo = {
      ...state.userInfo,
      ...userInfo
    }
    state.isLogin = true
    state.isLoginApi = true
  },

  [LOG_OUT]: (state) => {
    state.isLogin = false
    state.userInfo = {}
  }
}

const actions = {
  logIn: ({
    commit
  }, data) => {
    commit('LOG_IN', data)
  },
  logOut: ({
    commit
  }) => {
    commit('LOG_OUT')
  },
  userUpdate: ({
    commit
  }, data) => {
    commit('setUserInfo', data)
  },
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}