// 用户管理
const customerManagement = (resolve) =>
  require(["@/pages/user-management/index"], resolve);
const customerList = (resolve) =>
  require(["@/pages/user-management/userList/index"], resolve);
// 反馈
const customerFeedback = (resolve) =>
  require(["@/pages/user-management/feedback/index"], resolve);
// 许愿清单
const customerWishList = (resolve) =>
  require(["@/pages/user-management/wishList/index"], resolve);
//   订阅申请
const customerSubscription = (resolve) =>
  require(["@/pages/user-management/subscription/index"], resolve);

export default {
  path: "/customers",
  name: "customer",
  component: customerManagement,

  children: [
    {
      path: "all",
      name: "customerList",
      component: customerList,
      meta: {
        keepAlive: true,
      },
    },
    {
      path: "feedbacks",
      name: "customerFeedbacks",
      component: customerFeedback,
      meta: {
        keepAlive: true,
      },
    },
    {
      path: "wishList",
      name: "customerWishLists",
      component: customerWishList,
      meta: {
        keepAlive: true,
      },
    },
    {
        path: "subscription",
        name: "customerSubscription",
        component: customerSubscription,
        meta: {
          keepAlive: true,
        },
      },
  ],
};
