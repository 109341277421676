import Vue from 'vue'
import Vuex from 'vuex'
import VuexAlong from 'vuex-along'

import user from './data/user'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const createVuexAlong = VuexAlong({
  name: 'vuex-along',
  local: {},
  session: {}
})

const store = new Vuex.Store({
  modules: {
    user
  },
  strict: debug,
  plugins: [createVuexAlong]
  // plugins: debug ? [createLogger()] : []
})

export default store;