// 资产管理
const assets = resolve => require(["@/pages/asset-management/index"], resolve)

// 全部资产
const allAssets = resolve => require(["@/pages/asset-management/allAssets/index"], resolve)
// 资产审批
const assetApproval = resolve => require(["@/pages/asset-management/assetsApprove/index"], resolve)
// 市场资产
const publishedAssets = resolve => require(["@/pages/asset-management/marketAssets/index"], resolve)
// 资产分类
const assetCategories = resolve => require(["@/pages/asset-management/assetsClassification/index"], resolve)

// 资产库 (临时)
const warehouse = resolve => require(["@/pages/asset-management/warehouse/index"], resolve)

//免费资产
const free = resolve => require(["@/pages/asset-management/free-assets/index.vue"], resolve)

//组合资产
const property =  resolve => require(["@/pages/asset-management/portfolioAssets/index.vue"], resolve)

export default {
    path: '/assets',
    name: 'assets',
    component: assets,
    meta: {
        keepAlive: true
    },

    children: [{
            path: 'all',
            name: 'allAssets',
            component: allAssets,
            meta: {
                keepAlive: true
            },
        },
        {
            path: 'approval',
            name: 'assetApproval',
            component: assetApproval,
            meta: {
                keepAlive: true
            },
        },
        {
            path: 'published',
            name: 'publishedAssets',
            component: publishedAssets,
            meta: {
                keepAlive: true
            },
        },
        {
            path: 'categories',
            name: 'assetCategories',
            component: assetCategories,
            meta: {
                keepAlive: true
            },
        },
        {
            path: 'warehouse',
            name: 'warehouse',
            component: warehouse,
            meta: {
                keepAlive: true
            },
        },
        {
            path: 'free',
            name: 'free',
            component: free,

        },
        {
            path: 'property',
            name: 'property',
            component: property,
        },
    ]
}
