import axios from "../uitls/require";

export default {
  // 创建管理用户
  createAdminUser(userInfo) {
    return axios.post("/admin/user/create", userInfo);
  },
  // 更新管理用户
  updateAdminUser(userInfo) {
    return axios.post("/admin/user/update", userInfo);
  },
  // 管理用户的信息
  adminUserInfo(user_id) {
    return axios.get("/admin/user?user_id=" + user_id);
  },
  adminUserInfos() {
    return axios.get("/admin/user");
  },
  // 用户列表
  adminUserList(params) {
    return axios.post("/admin/user/list", params);
  },
  // 删除用户
  adminUserDelete(userid) {
    return axios.get("/admin/user/delete?user_id=" + userid);
  },
  // 更新用户密码
  adminUserPwdEdit(param) {
    return axios.post("/admin/user/reset-password", param);
  },
  // 许愿清单
  collectionList(params) {
    return axios.post("/admin/collect-feedback/list", params);
  },
  // 查看许愿清单
  collectionInfo(id) {
    return axios.get("/admin/collect-feedback/info?id=" + id);
  },
  //处理许愿清单
  changeStatus(params) {
   return axios.post("/admin/collect-feedback/change-status", params);
  }


};
