const assetInfo = resolve => require(['@/pages/asset-management/allAssets/info'], resolve)
const assetPub = resolve => require(['@/pages/asset-management/allAssets/publish'], resolve)
const approvalInfo = resolve => require(["@/pages/asset-management/assetsApprove/info"], resolve)

const categoryUpdate = resolve => require(["@/pages/asset-management/assetsClassification/edit"], resolve)

const assetChange = resolve => require(['@/pages/asset-management/marketAssets/mkt-components/mkt-change.vue'], resolve)
const marketInfo = resolve => require(['@/pages/asset-management/marketAssets/mkt-components/mkt-info.vue'], resolve)
const warehouseInfo = resolve => require(['@/pages/asset-management/warehouse/detail/detail.vue'], resolve)
const freeAssetCreate = resolve => require(['@/pages/asset-management/free-assets/add/add.vue'], resolve)
const freeAssetinfo = resolve => require(['@/pages/asset-management/free-assets/detail/detail.vue'], resolve)
const propertyAssetCreate = resolve => require(['@/pages/asset-management/portfolioAssets/add/add.vue'], resolve)

export const assetDetails = {
    path: '/asset',
    name: 'assetDetails',
    component: assetInfo,

}
export const publishDetails = {
    path: '/asset/publish',
    name: 'publish',
    component: assetPub
}
export const approvalDetails = {
    path: '/approval-details',
    name: 'approvalDetails',
    component: approvalInfo
}

export const categoryDetails = {
    path: '/category',
    name: 'categoryUpdate',
    component: categoryUpdate
}
export const assetsChange = {
    path: '/asset-change',
    name: 'assetChange',
    component: assetChange
}
export const marketAssetInfos = {
    path: '/mkt-info',
    name: 'marketInfo',
    component: marketInfo
}
export const warehouseDetail = {
    path: '/warehouse-detail',
    name: 'warehouseDetail',
    component: warehouseInfo
}
export const freeCreate = {
    path: '/free-create',
    name: 'freeCreate',
    component: freeAssetCreate
}
export const freeDetail = {
    path: '/free-detail',
    name: 'freeDetail',
    component: freeAssetinfo
}
export const  propertyCreate = {
    path: '/property-create',
    name: 'propertyCreate',
    component: propertyAssetCreate
}
