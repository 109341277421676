<template>
  <div>
    <el-container class="bodyStyle">
      <el-aside width="15%" class="aside-list">
        <div class="userInfo">
          <div class="userImg" @click.stop="personals">
            <img src="../../assets/images/headPortrait.jpg" alt />
          </div>
          <div class="userName">
            <div class="person-name" @click.stop="personals">{{ currentUsers.username }}</div>
            <div class="person-info">
              <div class="role-name">{{ roleName }}</div>
              <div class="log-out" @click="logout">退出</div>
            </div>
          </div>

          <el-dialog
            top="12%"
            :close-on-click-modal="false"
            :visible.sync="dialogVisibleLogout"
            width="458px"
            class="pop-dialog"
            :modal-append-to-body="false"
          >
            <div class>
              <div class="fz-18">
                确认退出
                <span class="fz-24 bold">{{ userName }}</span>
              </div>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button class="confirm-button" @click="sure">确认</el-button>
              <el-button
                class="cancel-button"
                style="margin-left: 25%"
                @click="dialogVisibleLogout = false"
              >取消</el-button>
            </span>
          </el-dialog>
        </div>
        <div class="menu-style" id="aside-left">
          <el-menu
            class="listStyle"
            :default-active="$route.path"
            :class="{ active: $route.path.indexOf('home') !== -1 }"
          >
            <router-link to="/home" style="text-decoration: none">
              <el-menu-item index="1" style="padding: 0">
                <i class="iconfont icon-hover fz-26">&#xe659;</i>
                <span class="iconTitle">首页</span>
              </el-menu-item>
            </router-link>
          </el-menu>

          <el-menu
            v-if="hasResource('customer')"
            class="listStyle"
            :default-active="$route.path"
            :class="{ active: $route.path.indexOf('customers') !== -1 || $route.path.indexOf('feedback') !== -1 }"
          >
            <router-link to="/customers" style="text-decoration: none">
              <el-menu-item index="1" style="padding: 0">
                <i class="iconfont fz-26">&#xe658;</i>
                <span class="iconTitle">用户管理</span>
              </el-menu-item>
            </router-link>
          </el-menu>

          <el-menu
            v-if="hasResource('assets')"
            class="listStyle"
            :default-active="$route.path"
            :class="{ active: $route.path.indexOf('asset') !== -1 || $route.path.indexOf('approval-details') !== -1 || $route.path.indexOf('category')!== -1}"
          >
            <router-link to="/assets" style="text-decoration: none">
              <el-menu-item index="1" style="padding: 0">
                <i class="iconfont fz-26">&#xe655;</i>
                <span class="iconTitle">资产管理</span>
              </el-menu-item>
            </router-link>
          </el-menu>

          <el-menu
            v-if="hasResource('transaction_management')"
            class="listStyle"
            :default-active="$route.path"
            :class="{ active: $route.path.indexOf('transactions') !== -1 }"
          >
            <router-link to="/transactions" style="text-decoration: none">
              <el-menu-item index="1" style="padding: 0">
                <i class="iconfont fz-26">&#xe604;</i>
                <span class="iconTitle">交易管理</span>
              </el-menu-item>
            </router-link>
          </el-menu>

          <el-menu
            v-if="hasResource('operation_management')"
            class="listStyle"
            :default-active="$route.path"
            :class="{ active: $route.path.indexOf('operations') !== -1 || $route.path.indexOf('publicity-image') !== -1 || $route.path.indexOf('featured-item') !== -1 || $route.path.indexOf('collection')!==-1 || $route.path.indexOf('message-info')!==-1}"
          >
            <router-link to="/operations" style="text-decoration: none">
              <el-menu-item index="1" style="padding: 0">
                <i class="iconfont fz-26">&#xe655;</i>
                <span class="iconTitle">运营管理</span>
              </el-menu-item>
            </router-link>
          </el-menu>
          <el-menu
            v-if="hasResource('activation_management')"
             class="listStyle"
            :default-active="$route.path"
            :class="{ active: $route.path.indexOf('inception-key') !== -1 }"
          >
            <router-link to="/inception-key" style="text-decoration: none">
              <el-menu-item index="1" style="padding: 0">
                <i class="iconfont fz-26">&#xe699;</i>
                <span class="iconTitle">DT激活码</span>
              </el-menu-item>
            </router-link>
          </el-menu>


          <el-menu
            v-if="hasResource('subscription_code')"
             class="listStyle"
            :default-active="$route.path"
            :class="{ active: $route.path.indexOf('subscribe') !== -1 }"
          >
            <router-link to="/subscribe" style="text-decoration: none">
              <el-menu-item index="1" style="padding: 0">
                <i class="iconfont fz-26">&#xe60a;</i>
                <span class="iconTitle">订阅码</span>
              </el-menu-item>
            </router-link>
          </el-menu>

          <el-menu
            v-if="hasResource('promotion_management')"
            class="listStyle"
            :default-active="$route.path"
            :class="{ active: $route.path.indexOf('promotions') !== -1 }"
          >
            <router-link to="/promotions" style="text-decoration: none">
              <el-menu-item index="1" style="padding: 0">
                <i class="iconfont fz-26">&#xe605;</i>
                <span class="iconTitle">促销管理</span>
              </el-menu-item>
            </router-link>
          </el-menu>

          <el-menu
            v-if="hasResource('data_report')"
            class="listStyle"
            :default-active="$route.path"
            :class="{ active: $route.path.indexOf('stats') !== -1 }"
          >
            <router-link to="/stats" style="text-decoration: none">
              <el-menu-item index="1" style="padding: 0">
                <i class="iconfont fz-26">&#xe602;</i>
                <span class="iconTitle">数据统计</span>
              </el-menu-item>
            </router-link>
          </el-menu>

          <el-menu
            v-if="hasResource('system_settings')"
            class="listStyle"
            :default-active="$route.path"
            :class="{ active: $route.path.indexOf('settings') !== -1 || $route.path.indexOf('role') !== -1 || $route.path.indexOf('agreement') !== -1}"
          >
            <router-link to="/settings" style="text-decoration: none">
              <el-menu-item index="1" style="padding: 0">
                <i class="iconfont fz-26">&#xe64f;</i>
                <span class="iconTitle">系统设置</span>
              </el-menu-item>
            </router-link>
          </el-menu>
        </div>
      </el-aside>

      <el-container class>
        <el-header>
          <nav-header></nav-header>
        </el-header>
        <el-main style="padding: 0" class="content-main">
          <router-view class="container" id="container"></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
import api from "../../api/user";
import api_logout from "../../api/login";
import navHeader from "./navHeader";

export default {
  name: "NavMenu",
  components: {
    navHeader,
  },
  data() {
    return {
      userName: localStorage.getItem("username"),
      roleName: "",
      dialogVisibleLogout: false,
      screenHeight: this.getClientHeight(),
    };
  },
  methods: {
    // 个人中心跳转
    personals() {
      if (this.$route.name === "personal") {
        return;
      }
      this.$router.push({ path: "/personal" });
    },
    init() {
      api.adminUserInfos().then((res) => {
        if (res.data.code === 10200) {
          // this.userName = res.data.data.username;
          this.roleName = res.data.data.role.role_name;
        }
      });
    },
    // 登出
    logout() {
      this.dialogVisibleLogout = true;
    },
    sure() {
      api_logout.userLogout().then((res) => {
        if (res.data.code === 10200) {
          this.$router.push({ path: "/" });
          localStorage.clear();
        } else {
          this.$message(res.data.message);
        }
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="less" scoped>
@import "./scss/index.less";
</style>
