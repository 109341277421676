import axios from '../uitls/require'

export default {
  // 用户登录
  userLogin(userInfo) {
    return axios.post("/admin/user/login", userInfo)
  },
  // 账号登出
  userLogout() {
    return axios.get('/admin/user/logout')
  }
}