const stats = resolve => require(['@/pages/stats/stats'], resolve)
const balanceSummary = resolve => require(['@/pages/stats/balance/overview/overview-main'], resolve)
// const balanceDetail = resolve => require(['@/pages/stats/balance/detail/detail-main'], resolve)
const downloadCollect = resolve => require(['@/pages/stats/download/collect/collect-main'], resolve)
const downloadParticulars = resolve => require(['@/pages/stats/download/particulars/particulars-main'], resolve)

export default {
    path: '/stats',
    name: 'stats',
    component: stats,
    meta: {
        // keepAlive: true
    },
    children: [{
            path: 'balance-summary',
            name: 'balanceSummary',
            component: balanceSummary,
            meta: {
                keepAlive: true
            },
        },
        // {
        //     path: 'balance-detail',
        //     name: 'balanceDetail',
        //     component: balanceDetail,
        //     meta: {
        //         keepAlive: true
        //     },
        // },
        {
            path: 'download-collect',
            name: 'downloadCollect',
            component: downloadCollect,
            // meta: {
            //     keepAlive: true
            // },
        },
        {
            path: 'download-particulars',
            name: 'downloadParticulars',
            component: downloadParticulars,
            // meta: {
            //     keepAlive: true
            // },
        }
    ]
}
