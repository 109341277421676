export default {
  customerManageTabs: [
    {
      path: "/customers/all",
      tabName: "用户列表",
      routeName: "customerList",
      access: "customer_management",
    },
    {
      path: "/customers/feedbacks",
      tabName: "问题反馈",
      routeName: "customerFeedbacks",
      access: "feedback_management",
    },
    {
      path: "/customers/wishList",
      tabName: "许愿清单",
      routeName: "customerWishLists",
      access: "collect_feedback_management",
    },
    {
        path: "/customers/subscription",
        tabName: "订阅申请",
        routeName: "customerSubscription",
        access: "subscription_apply_management",
      },
  ],
  assetManageTabs: [
    {
      path: "/assets/all",
      tabName: "全部资产",
      routeName: "allAssets",
      access: "asset_management",
    },
    {
      path: "/assets/approval",
      tabName: "资产审批",
      routeName: "assetApproval",
      access: "review_management",
    },
    {
      path: "/assets/published",
      tabName: "市场资产",
      routeName: "publishedAssets",
      access: "publish_asset_management",
    },
    // {
    //   path: "/assets/categories",
    //   tabName: "资产分类",
    //   routeName: "assetCategories",
    //   access: "category_management",
    // },
    // {
    //     path: "/assets/warehouse",
    //     tabName: "资产库",
    //     routeName: "warehouse",
    //     access: "asset_centre_management",
    // },
    {
        path: "/assets/free",
        tabName: "免费资产",
        routeName: "free",
        access: "asset_free_management",
    },
    {
        path: "/assets/property",
        tabName: "组合资产",
        routeName: "property",
        access: "asset_group_management",
    }
  ],
  transactionTabs: [
    {
      path: "/transactions/plans",
      tabName: "积分方案",
      routeName: "purchasePlans",
      access: "purchase_plan_management",
    },
    {
        path: "/transactions/planApproval",
        tabName: "方案审批",
        routeName: "planApproval",
        access: "purchase_plan_audit_management",
    },
    {
      path: "/transactions/orders",
      tabName: "充值订单",
      routeName: "purchaseOrders",
      access: "order_management",
    },
    {
      path: "/transactions/refunds",
      tabName: "退款订单",
      routeName: "refunds",
      access: "refund_management",
    },
  ],
  operationTabs: [
    {
      path: "/operations/scene",
      tabName: "场景包",
      routeName: "scene",
      access: "scene_pack_management",
    },
    {
      path: "/operations/banner",
      tabName: "banner",
      routeName: "banner",
      access: "banner_management",
    },
    // {
    //   path: "/operations/cms-images",
    //   tabName: "宣传图",
    //   routeName: "cmsImages",
    //   access: "cms_image_management",
    // },
    {
      path: "/operations/popular-search",
      tabName: "热门搜索词",
      routeName: "popularSearch",
      access: "popular_search_management",
    },
    {
      path: "/operations/featured-items",
      tabName: "推荐资产",
      routeName: "featuredItems",
      access: "feature_management",
    },
    {
      path: "/operations/collections",
      tabName: "推荐系列",
      routeName: "collections",
      access: "collection_management",
    },
    {
      path: "/operations/notice",
      tabName: "消息推送",
      routeName: "notice",
      access: "message_management",
    },
  ],
  inceptionTabs: [
    {
      path: "/inception-key/activation",
      tabName: "激活码",
      routeName: "activationManagement",
      access: "activation_code_management",
    },
    // {
    //   path: "/inception-key/application",
    //   tabName: "申请记录",
    //   routeName: "applicationManagement",
    //   access: "activation_code_application_management",
    // },
  ],
  subscribeTabs: [
    {
      path: "/subscribe/code",
      tabName: "订阅码",
      routeName: "codeManagement",
      access: "coupon_management",
    },
  ],
  promotionTabs: [
    {
        path: "/promotions/pull-new-management",
        tabName: "拉新维护",
        routeName: "pullNewManagement",
        access: "activity_management",
    },
    {
        path: "/promotions/user-incentive",
        tabName: "用户激励",
        routeName: "userIncentiveManagement",
        access: "activity_management",
    },
    {
        path: "/promotions/coupons",
        tabName: "兑换码",
        routeName: "couponManagement",
        access: "coupon_management",
      },
  ],
  searchTabs: [
    {
      path: "/searches/popular",
      tabName: "热门搜索",
      routeName: "popularSearches",
      access: "popular_search_management",
    },
  ],
  statsTabs: [
    {
      path: "/stats/balance-summary",
      tabName: "积分汇总",
      routeName: "balanceSummary",
      access: "summary_report",
    },
    {
      path: "/stats/download-collect",
      tabName: "下载汇总",
      routeName: "downloadCollect",
      access: "download_summary_report",
    },
    {
      path: "/stats/download-particulars",
      tabName: "下载明细",
      routeName: "downloadParticulars",
      access: "detail_report",
    },
  ],
  settingsTabs: [
    {
      path: "/settings/accounts",
      tabName: "账户管理",
      routeName: "accountManagement",
      access: "admin_user_management",
    },
    {
      path: "/settings/roles",
      tabName: "角色管理",
      routeName: "roleManagement",
      access: "role_management",
    },
    {
      path: "/settings/agreements",
      tabName: "协议管理",
      routeName: "agreementManagement",
      access: "agreement_management",
    },
    {
      path: "/settings/notification",
      tabName: "系统通知",
      routeName: "systematicNotification",
      access: "system_message_management",
    },
  ],
};
