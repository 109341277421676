// 运营管理
const operationManagement = resolve => require(["@/pages/operation-management/index"], resolve)
//宣传图
// const cmsImgs = resolve => require(["@/pages/operation-management/cms-images/index"], resolve)
//推荐资产
const featuredAssets = resolve => require(["@/pages/operation-management/featured-assets/index"], resolve)
//推荐分类
const collections = resolve => require(["@/pages/operation-management/collections/index"], resolve)
// 热门搜索词
const popularSearch = resolve => require(['@/pages/operation-management/popular-search/search-list'],resolve)
const notice = resolve => require(["@/pages/operation-management/notice/index"], resolve)
const scene = resolve => require(["@/pages/operation-management/scene/index.vue"], resolve)
const banner = resolve => require(["@/pages/operation-management/banner/index.vue"], resolve)

export default {
  path: '/operations',
  name: 'operations',
  component: operationManagement,

  children: [{
        path: 'scene',
        name: 'scene',
        component: scene
    },
    {
      path: 'banner',
      name: 'banner',
      component: banner
    },
    // {
    //   path: 'cms-images',
    //   name: 'cmsImages',
    //   component: cmsImgs,
    //   meta: {
    //     keepAlive: true
    //   },
    // },
    {
      path: 'popular-search',
      name: 'popularSearch',
      component: popularSearch,
      meta: {
        keepAlive: true
      },
    },
    {
      path: 'featured-items',
      name: 'featuredItems',
      component: featuredAssets,
      meta: {
        keepAlive: true
      },
    },
    {
      path: 'collections',
      name: 'collections',
      component: collections,
      meta: {
        keepAlive: true
      },
    },
    {
      path: 'notice',
      name: 'notice',
      component: notice,
      meta: {
        keepAlive: true
      },
    },
  ]
}
