import Vue from 'vue';
import Router from 'vue-router';
import container from '@/pages/layout/container.vue'
// 登录
import login from '@/pages/login/index.vue'

// 用户管理
import customers from '@/router/customers/customers'
import {
  customerDetails,
  feedbackDetails,
  subscribesDetails
} from '@/router/customers/details'

// 交易管理
import transactions from '@/router/transactions/transactions'

// 促销管理
const promosManagement = resolve => require(["@/pages/promo-management/management.vue"], resolve)
import promos from "@/router/promotions/promos";
import { userIncentiveDetail, activityIntroduce, activityRule, activityPermission } from '@/router/promotions/details'


//订阅管理
import {subscribe, subscribeAdd} from '@/router/subscribes/subscribe'

// 数据统计
import stats from '@/router/stats/stats'

// 系统设置
import systemSettings from '@/router/settings/system-settings'
import {
  agreementDetails,
  roleDetails
} from '@/router/settings/details';


const personal = resolve => require(['@/pages/home/content/personal'], resolve)
const home = resolve => require(['@/pages/home/index'], resolve)

// 资产管理
const assetsManagement = resolve => require(["@/pages/asset-management/management"], resolve)
import assets from '@/router/assets/assets'
import {
  publishDetails,
  approvalDetails,
  assetDetails,
  categoryDetails,
  assetsChange,
  marketAssetInfos,
  warehouseDetail,
  freeCreate,
  freeDetail,
  propertyCreate
} from '@/router/assets/details';

// 运营管理
const operationManagement = resolve => require(["@/pages/operation-management/management"], resolve)
import operations from '@/router/operations/operations'
import {
  collectionDetails,
  featuredAssetsDetails,
//   publicityImgDetails,
  messageDetail,
  messageImgDetail,
  sceneAdd,
  bannerAdd
} from '@/router/operations/details';
//运营管理end

// inception 激活码
const inceptionKeyManagement = resolve => require(["@/pages/inception-key/management"], resolve)
import {inception, activationAdd} from '@/router/inception/inception'

const originalPush = Router.prototype.push

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)


export const routes = [{
    path: '/',
    name: 'login',
    component: login
  },
  {
    path: '/main',
    name: 'main',
    component: container,
    redirect: {
      name: 'home'
    },
    children: [{
        path: '/home',
        name: 'home',
        component: home
      },
      {
        path: '/personal',
        name: 'personal',
        component: personal
      },
      {
        path: '/assets-management',
        name: 'assets-management',
        component: assetsManagement,
        children: [
          assets,
          publishDetails,
          approvalDetails,
          assetDetails,
          categoryDetails,
          assetsChange,
          marketAssetInfos,
          warehouseDetail,
          freeCreate,
          freeDetail,
          propertyCreate
        ]
      },
      {
          path: '/promotions',
          name: 'promo-management',
          component: promosManagement,
          children: [
            promos,
            userIncentiveDetail,
            activityIntroduce,
            activityRule,
            activityPermission
          ]
      },
      {
        path: '/operation-management',
        name: 'operation-management',
        component: operationManagement,
        children: [
          operations,
          collectionDetails,
          featuredAssetsDetails,
        //   publicityImgDetails,
          messageDetail,
          messageImgDetail,
          sceneAdd,
          bannerAdd
        ]
      },
      {
        path: '/inception-management',
        name: 'inception-management',
        component: inceptionKeyManagement,
        children: [
            inception,
            activationAdd
        ]
      },

      // 单独页面
      subscribesDetails,
      feedbackDetails,
      customerDetails,
      categoryDetails,

      roleDetails,
      agreementDetails,

      // 左侧菜单页面
      customers,
      // assets,
      transactions,
      subscribe,
      subscribeAdd,
      stats,
      systemSettings,
    ]
  },
]

const router = new Router({
  mode: 'history',
  routes: routes
})


export default router
