const roleInfo = resolve => require(["@/pages/system-settings/roleManagement/edit"], resolve)
const agreementInfo = resolve => require(["@/pages/system-settings/agreementManagement/edit"], resolve)

export const roleDetails = {
    path: '/role',
    name: 'roleDetails',
    component: roleInfo
}

export const agreementDetails = {
    path: '/agreement',
    name: 'agreementDetails',
    component: agreementInfo,
}