// const publicityImgEdit = resolve => require(["@/pages/operation-management/cms-images/edit"], resolve)

const featuredAssetsInfo = resolve => require(["@/pages/operation-management/featured-assets/add"], resolve)
const collectionInfo = resolve => require(["@/pages/operation-management/collections/add"], resolve)

const messageInfo = resolve => require(["@/pages/operation-management/notice/edit/edit-text"], resolve)
const messageImgInfo = resolve => require(["@/pages/operation-management/notice/edit/edit-text-img"], resolve)
const sceneAddCom = resolve => require(["@/pages/operation-management/scene/sceneAdd/index.vue"], resolve)
const bannerAddCom = resolve => require(["@/pages/operation-management/banner/bannerAdd/index.vue"], resolve)

// const textImgInfo =  resolve => require(["@/pages/operation-management/notice/textImg"], resolve)

// export const publicityImgDetails = {
//     path: '/publicity-image',
//     name: 'publicityImgEdit',
//     component: publicityImgEdit

// }

export const featuredAssetsDetails = {
    path: '/featured-item',
    name: 'featuredAssetsInfo',
    component: featuredAssetsInfo
}

export const collectionDetails = {
    path: '/collection',
    name: 'collectionDetails',
    component: collectionInfo
}

export const messageDetail = {
    path: '/message-info',
    name: 'messageInfo',
    component: messageInfo
}

export const messageImgDetail = {
    path: '/message-info-img',
    name: 'messageImgInfo',
    component: messageImgInfo
}
// 新增资产包
export const sceneAdd = {
    path: '/operations/add-scene',
    name: 'sceneAdd',
    component: sceneAddCom
}
// 新增banner
export const bannerAdd = {
    path: '/operations/add-banner',
    name: 'bannerAdd',
    component: bannerAddCom
}
// 2.9版本图文消息推送预览，改成弹窗展示，此页面不需要了
// export const textImgDetail = {
//     path: '/img-text-preview',
//     name: 'textImgInfo',
//     component: textImgInfo
// }
