const subscribeManagement = (resolve) => require(['@/pages/subscribe-management/index'], resolve);
const subscribeCode = (resolve) => require(['@/pages/subscribe-management/code/list.vue'], resolve);
const subscribeCodeAdd = (resolve) => require(['@/pages/subscribe-management/add_subscribecode/index.vue'], resolve);

export const subscribe =  {
    path: '/subscribe',
    name: 'subscribe',
    component: subscribeManagement,
    redirect: {
        name: 'codeManagement'
    },
    children: [{
        path: 'code',
        name: 'codeManagement',
        component: subscribeCode,
        meta: {
            keepAlive: true
        }
    }]
};
export const subscribeAdd = {
    path: '/subscribe-add',
    name: 'subscribeCodeAdd',
    component: subscribeCodeAdd,
}
