const customerData = resolve => require(["@/pages/user-management/userList/user-data"], resolve)
const feedbacks = resolve => require(["@/pages/user-management/feedback/detail"], resolve)
const subscribesDetail =resolve => require(["@/pages/user-management/subscription/detail/detail"], resolve)

export const customerDetails = {
    path: '/customer',
    name: 'customerDetails',
    component: customerData,
    meta: {
        keepAlive: true
    },
}

export const feedbackDetails = {
        path: '/feedback',
        name: 'feedbackDetails',
        component: feedbacks,
        meta: {
            keepAlive: true
        }
}

export const subscribesDetails= {
    path: '/subscribes-detail',
    name: 'subscribesDetails',
    component: subscribesDetail,
}
