// 全局的config配置 
const modeUrlObj = {
  // 生产环境
  'production': {
    baseURL: 'https://api.pbrmax.cn/zh-CN',
    authBaseURL: 'production'
  },
  // 开发环境
  'development': {
    baseURL: 'http://qa.api.pbrmax.cn:22610/zh-CN',
    authBaseURL: "development"
  },
  // 测试环境
  'test': {
    baseURL: 'http://qa.api.pbrmax.cn:22610/zh-CN',
    authBaseURL: 'test'
  },
  'pre': {
    baseURL: 'https://pre.api.pbrmax.cn:444/zh-CN',
    authBaseURL: 'pre'
  }
}
export default modeUrlObj[process.env.NODE_ENV]
