import axios from 'axios'
import config from '../config/index' //路径配置
import vm from '../main'
import router from '../router/index'
import { MessageBox } from 'element-ui'

import  VueQuillEditor from 'vue-quill-editor'
import env from 'env'


// 全局配置
// axios.defaults.baseURL = config.baseURL
axios.defaults.baseURL = env.serveUrls
axios.defaults.timeout = 6000000
//headers
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
axios.defaults.headers.patch['Content-Type'] = 'application/json;charset=UTF-8'
axios.defaults.headers.delete['Content-Type'] = 'application/json;charset=UTF-8'

// request 拦截器
axios.interceptors.request.use(
  function (config) {
      config.headers.Authorization = "Bearer " + localStorage.getItem('token');
      // 请求头统加 name  version
    config.headers['app-name'] = 'WEB';
    config.headers['app-version'] = '2.0.0';

    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)


// response 拦截器
axios.interceptors.response.use(
  function (response) {
    return response
  },
  // 请求返回 status 为非200 的情况下的处理
  function (error) {
    // 请求超时 及 出错的时候统一关闭loading
    if (vm && vm.$currentLoading) vm.$closeLoading()
    /*
    	400 -- 请求错误
    	401 -- 未授权，请登录
    	403 -- 拒绝访问
    	404 -- 请求地址出错: ${err.response.config.url}
    	408 -- 请求超时
    	500 -- 服务器内部错误
    	501 -- 服务未实现
    	502 -- 网关错误
    	503 -- 服务不可用
    	504 -- 网关超时
    	505 -- HTTP版本不受支持
    */
    let response = error.response
    if (response) {
      let errorStatus = response.status // 错误码
      if (errorStatus === 400) {
        return response
      }
      if (errorStatus === 401) {
        window.location.href = '/'
      } else if (errorStatus >= 500) {
        this.$$error('系统错误')
      }
    }
    return Promise.reject(error)

    // 请求超时后的重复请求处理
    // // 如果配置不存在或未设置重试选项，则 reject
    // if(!config || !config.retry) return Promise.reject(error);

    // // 设置变量以跟踪重试次数
    // config.__retryCount = config.__retryCount || 0;

    // // 检查是否已经超出总重试次数
    // if(config.__retryCount >= config.retry) return Promise.reject(err);

    // // 增加重试次数
    // config.__retryCount += 1;

    // // Create new promise to handle exponential backoff
    // var backoff = new Promise(function(resolve) {
    // 	setTimeout(function() {
    // 		resolve();
    // 	}, config.retryDelay || 1);
    // });

    // // 在设置的间隙值之后 以Promise 的形式返回 重试的axios请求
    // return backoff.then(function() {
    // 	return axios(config);
    // });
  }
)

export default axios
