const userIncentiveInfo  = resolve => require(['@/pages/promo-management/user-incentive-manage/detail/detail.vue'], resolve)
const activity  = resolve => require(['@/pages/promo-management/pull_new_manage/activity/activity.vue'], resolve)
const activityRuleCom  = resolve => require(['@/pages/promo-management/pull_new_manage/activity_rule/activity_rule.vue'], resolve)
const activityPermissionCom  = resolve => require(['@/pages/promo-management/pull_new_manage/activity_permission/activity_permission.vue'], resolve)
// 用户激励详情
export const userIncentiveDetail= {
    path: '/user-incentive-detail',
    name: 'userIncentiveDetail',
    component: userIncentiveInfo
}
// 拉新维护活动介绍
export const activityIntroduce= {
    path: 'activity-introduce',
    name: 'activityIntroduce',
    component: activity
}
// 拉新维护活动规则
export const activityRule = {
    path: 'activity-rule',
    name: 'activityRule',
    component: activityRuleCom
}
// 拉新维护活动权限
export const activityPermission = {
    path: 'activity-permission',
    name: 'activityPermission',
    component: activityPermissionCom
}

