const systemManagement = resolve => require(["@/pages/system-settings/index"], resolve)

const roleManagement = resolve => require(["@/pages/system-settings/roleManagement/index"], resolve)
const updateRole = resolve => require(["@/pages/system-settings/roleManagement/edit"], resolve)

const agreementManagement = resolve => require(["@/pages/system-settings/agreementManagement/index"], resolve)
const updateAgreement = resolve => require(["@/pages/system-settings/agreementManagement/edit"], resolve)
const accountManagement = resolve => require(["@/pages/system-settings/accountManagement/index"], resolve)
const systematicNotification = resolve => require(['@/pages/system-settings/systematic-notification/index'], resolve)



export default {
  path: '/settings',
  name: 'settings',
  component: systemManagement,

  children: [{
      path: 'accounts',
      name: 'accountManagement',
      component: accountManagement,
      meta: {
        keepAlive: true
      }
    },
    {
      path: 'roles',
      name: 'roleManagement',
      component: roleManagement,
      meta: {
        keepAlive: true
      },
    },
    {
      path: 'agreements',
      name: 'agreementManagement',
      component: agreementManagement,
      meta: {
        keepAlive: true
      },
    },
    {
      path: 'notification',
      name: 'systematicNotification',
      component: systematicNotification,
      meta: {
        keepAlive: true
      },
    },
  ]
}