<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
    name: 'App'
};
</script>

<style lang="less">
@import "@/assets/font/fonts.less";

* {
    font-weight: 400;
    font-family: NotoSans, Nimbus, Arial, Helvetica, sans-serif;
}

/* stylelint-disable-next-line selector-max-type */
body {
    background: #f5f5f5;
}

</style>
