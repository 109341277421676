import Vue from "vue";
import VueDOMPurifyHTML  from "vue-dompurify-html";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";

// 导入element-ui
import ElementUI from "element-ui";

import VueQuillEditor from "vue-quill-editor";

// 注册全局样式
import "@/assets/scss/style-sheet-index.less";
import "@/assets/scss/element-variables.scss";

// 注册公用mixin
import mixin from "./mixins/global";

// require styles 引入样式
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

Vue.use(ElementUI);
Vue.mixin(mixin);

Vue.use(VueQuillEditor);
Vue.use(VueDOMPurifyHTML);

// rsa加密
import JsEncrypt from "jsencrypt";
Vue.prototype.$jsEncrypt = JsEncrypt;

// 复制到剪贴板
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

// 引入全局样式
import "../src/assets/resets.css";
import tabs from "@/static/vars/header-tabs";
Vue.prototype.$tabs = tabs;

import moment from "moment";
Vue.prototype.$moment = moment;
moment.locale("zh-cn");

import Base64 from "@/uitls/base64.js";
Vue.prototype.$Base64 = Base64;

Vue.config.productionTip = false;

// 添加消息总线，处理消息通讯（主要用于非父子关系组件之间）
let msgBus = new Vue();
Vue.prototype.msgBus = msgBus;

const vm = new Vue({
  render: (h) => h(App),
  store,
  router,
}).$mount("#app");

export default vm;
