<template>
  <div class="login-content">
    <div class="back-image"></div>
    <div class="login-box" @click="clear" v-loading="loading">
      <div class="box-content">
        <div class="login-img">
          <img src="../../assets/images/Group.png" alt />
        </div>
        <div class="text">Background management system</div>
        <div class="pass-input">
          <el-input class placeholder="用户名 / 邮箱" v-model="username" @input="clear" />
        </div>
        <div class="pass-input">
          <el-input
            placeholder="密码"
            class="input-pass"
            v-model="password"
            type="password"
            show-password
            @keypress.native.enter="signIn()"
            @input="clear"
          ></el-input>
        </div>
        <div class="message" :title="hintMessage">{{ hintMessage }}</div>
        <div>
          <el-button class="login-button" @click="signIn()">登 录</el-button>
        </div>
        <div class="danger">资产管理平台系统，非万生华态内部工作人员，不得擅自登录。如有损失，将追究法律责任。</div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../api/login";
import userApi from "../../api/user";
export default {
  data() {
    return {
      username: "",
      showPassWord: false,
      password: "",
      pubKey:
        "-----BEGIN PUBLIC KEY-----\n" +
        "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDK969z3jGTsguG4jHQgT2Pnk+F\n" +
        "RCWqpYs9aisvkelztp2vQxIzjyzQecKYC1LUsqtAlVOH7qbS1ET2i5+RLNuBzRW4\n" +
        "iZcsYnLtpdr4cuBoGww9txwUDXbx9k7AgQCxSi3KEBvdYDktWT/sY3SNYHvlTw2N\n" +
        "9BtS4k1CNaEq8TAL2wIDAQAB\n" +
        "-----END PUBLIC KEY-----",
      hintMessage: "",
      allResourcesKey: [],
      loading:false
    };
  },
  methods: {
    clear() {
      this.hintMessage = ''
    },
    // 提交测试
    signIn() {
      let jse = new this.$jsEncrypt();
      jse.setPublicKey(this.pubKey); // 加入rsa public key
      let pwds = jse.encrypt(this.password);
      if (this.username === "") {
        this.$message({ type: "waring", message: "用户名不能为空！" });
        return false;
      } else if (this.password === "") {
        this.$message({ type: "waring", message: "密码不能为空！" });
        return false;
      }
      let data = {
        email: this.username,
        password: pwds,
      };
      this.loading = true;
      api.userLogin(data).then((res) => {
        if (res.data.code === 10200) {
          this.$$success(res.data.message);
          localStorage.setItem("token", res.data.data.token);
          localStorage.setItem("uid", res.data.data.user_id);
          localStorage.setItem("username", res.data.data.username);
          localStorage.setItem("email", res.data.data.email);
          localStorage.setItem('first-login',res.data.data.is_first_login)
          this.login(res.data.data)
          this.getUserinfo();
          this.$router.push({ path: "/main" });
        }
        if (res.data.code === 10101) {
          this.hintMessage = "*" + res.data.message;
        }
        if (res.data.code === 10102) {
          this.hintMessage = "*" + res.data.message;
        }
        if (res.data.code === 10103) {
          this.hintMessage = "*" + res.data.message;
        }
        this.loading = false;
      });
    },
    async getUserinfo() {
      await userApi.adminUserInfos().then((res) => {
        if (res.data.code === 10200) {
          let resource = res.data.data.resources;
          let resourceData = [];
          if (resource) {
            this.getAllResourceKeys(resource);
          }
          localStorage.setItem("user-resource", this.allResourcesKey);
        }
      });
    },
    getAllResourceKeys(data) {
      let keys = [];
      let key = {};
      for (key in data) {
        let resource = data[key];
        this.allResourcesKey.push(resource["id"]);
        if (resource["children"] != undefined && resource["children"] != []) {
          let resourceChildren = this.getAllResourceKeys(resource["children"]);
        }
      }
    },
  },
  mounted() {},
};
</script>

<style lang="less">
@import "scss/login.less";
</style>
